import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { first, find } from 'lodash'
import LinkWithPrevPath from 'src/components/LinkWithPrevPath'
import { SquareCloseButton, BenefitsTrial } from 'src/components/shared'
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils'
import { Button } from 'semantic-ui-react'
import { getProductDetails } from 'src/utils/sdk-utils'
import SEO from '../SEO'
import { ContentSelectors } from '@pvolve/sdk/src/redux/selectors'
import * as Styles from 'src/styles/start-trial-14.module.scss'

interface StartTrialPageProps {
    shopifyUrl?: string
    sku: string
}

interface Product {
    trial_days: number
    title: string
    sku: string
    cost: number
}

const StartTrialPage = ({ sku, shopifyUrl }: StartTrialPageProps) => {
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down)
    const [productDetails, setProductDetails] = useState<Product>()
    const [landscape, setLandscape] = useState<boolean>(false)

    const content = useSelector(ContentSelectors.asset.list)
    const backgroundImg = find(content, a => a?.fields?.title === 'start-trial')
    const backgroundImgUrl = backgroundImg?.fields.file.url ?? ''

    useEffect(() => {
        const load = async () => {
            try {
                if (!!sku) {
                    const response = await getProductDetails(sku)
                    const product = first(response)

                    if (product) {
                        setProductDetails({
                            cost: product.cost.currency_amount,
                            sku: product.sku,
                            title: product.title,
                            trial_days: product.subscription_details.trial_days,
                        })
                    }
                }
            } catch (error) {
                console.log({ error })
                return {}
            }
        }

        window.addEventListener('resize', sizeCalculation)
        sizeCalculation()
        load()
    }, [sku])

    const sizeCalculation = () => {
        const height: number = window?.innerHeight || 0
        const width: number = window?.innerWidth
        const isTabletPortraitOrSmaller: boolean = width <= 1023

        setLandscape(!isTabletPortraitOrSmaller && width > height)
    }

    const topText = (
        <div className={classNames(Styles.topText, landscape ? Styles.landscape : '')}>
            <h1 className="margin--0">Try P.volve free</h1>
            <p className="accent margin--0">for {productDetails?.trial_days} days</p>
        </div>
    )

    return (
        <>
            <SEO title={`Free ${productDetails?.trial_days} day trial`} />

            <div className={classNames(Styles.wrapper, landscape ? Styles.landscape : '')}>
                <div className={classNames(Styles.sideImageWrapper, landscape ? Styles.landscape : '')}>
                    <div
                        className={classNames(Styles.sideImage, landscape ? Styles.landscape : '')}
                        style={
                            landscape
                                ? {
                                    backgroundImage: `url("https:${backgroundImgUrl}")`,
                                }
                                : {
                                    backgroundImage: `
                            linear-gradient(rgba(255, 255, 255, 0.5),
                            rgba(255, 255, 255, 0.5)),
                            url("https:${backgroundImgUrl}")
                        `,
                                }
                        }
                    >
                        {topText}
                    </div>
                </div>
                <div className={classNames(Styles.copy, landscape ? Styles.landscape : '')}>
                    <SquareCloseButton />

                    {topText}

                    <p className={classNames(Styles.paragraphList, isMobile ? 'p2' : 'p1')}>
                        Get ready to know your body better:
                    </p>
                    <BenefitsTrial />

                    <div className={Styles.cta}>
                        <Button as={LinkWithPrevPath} to={`/checkout/?sku=${productDetails?.sku}`}>
                            Start free trial
                        </Button>

                        <p className={isMobile ? 'p2' : 'p1'}>
                            Then only ${productDetails?.cost} a month <br /> Not satisfied? Cancel any time.
                        </p>
                    </div>

                    <div className={Styles.footer}>
                        <p className="p2">
                            Already have an account? <LinkWithPrevPath to="/continue">Log in</LinkWithPrevPath>
                        </p>
                        <p className="p2">
                            <a href={`${shopifyUrl}/pages/student-discount`}>EDUCATION DISCOUNT</a>
                            {!isMobile && <span className={Styles.dividerLine}>|</span>}
                            <a href={`${shopifyUrl}/pages/health-heroes`}>FIRST RESPONDER DISCOUNT</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StartTrialPage
